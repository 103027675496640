import React, { Dispatch, SetStateAction, useState } from "react";
import Router from "next/router";
import { useTranslation } from "next-i18next";

import {
  APP_BUILD_INFO,
  IS_UNDER_PRODUCTION,
} from "@sellernote/_shared/src/constants";
import { useBodyScrollLock } from "@sellernote/_shared/src/hooks/common/useBodyScrollLock";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import Button from "@sellernote/_sds-v2/src/components/button/Button";
import Divide from "@sellernote/_sds-v2/src/components/Divide";
import MenuIcon from "@sellernote/_sds-v2/src/components/svgIcons/MenuIcon";
import SubmenuAnnouncementIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuAnnouncementIcon";
import SubmenuBookIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuBookIcon";
import SubmenuCalendarIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuCalendarIcon";
import SubmenuCodeIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuCodeIcon";
import SubmenuFlightIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuFlightIcon";
import SubmenuGiveMoneyIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuGiveMoneyIcon";
import SubmenuInternationalIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuInternationalIcon";
import SubmenuPlayerIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuPlayerIcon";
import SubMenuSourcingIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubMenuSourcingIcon";
import SubmenuSurpriseIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuSurpriseIcon";
import SubmenuTruckIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuTruckIcon";
import SubmenuVesselIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuVesselIcon";
import UserFilledIcon from "@sellernote/_sds-v2/src/components/svgIcons/UserFilledIcon";
import XMarkDefaultIcon from "@sellernote/_sds-v2/src/components/svgIcons/XMarkDefaultIcon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import useAlibabaSourcing from "../../../../hooks/common/useAlibabaSourcing";

import SubmenuItem from "../components/SubmenuItem";

import TempLanguageButton from "../../../TempLanguageButton";
import Logo from "../..//Logo";
import Logout from "../..//Logout";
import User from "../..//User";
import MenuItem from "./MenuItem";
import Styled from "./index.styles";

const MobileDivide = () => {
  return (
    <Divide
      lineStyle="line"
      thickness={1}
      type="horizontal"
      width="100%"
      color={COLOR.grayScale_300}
    />
  );
};

export default function MobileHeader({
  loggedIn,
  setIsVisibleLoginModal,
}: {
  loggedIn: boolean;
  setIsVisibleLoginModal: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation(["containers"]);

  const { alibabaUrl, alibabaGtmEvent } = useAlibabaSourcing();

  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);
  const [activeMenuLabel, setActiveMenuLabel] = useState<string>();

  useBodyScrollLock({
    enablesLocking: isVisibleDrawer,
  });

  const handleAuthClick = () => {
    if (!loggedIn) return setIsVisibleLoginModal(true);

    Router.push("/mypage");
  };

  const handleMenuSelect = (label: string) => {
    setActiveMenuLabel(label);
  };

  const handleDrawerClose = () => {
    setIsVisibleDrawer(false);
  };

  return (
    <>
      <Styled.mobileHeader>
        {/* 임시 언어 전환 버튼 */}
        {!IS_UNDER_PRODUCTION && <TempLanguageButton />}

        <Logo type="header" />

        <Styled.authAndMenuContainer loggedIn={loggedIn}>
          <div className="auth-container">
            {loggedIn ? (
              <User />
            ) : (
              <UserFilledIcon
                width={24}
                height={24}
                color={loggedIn ? COLOR.grayScale_900 : COLOR.grayScale_700}
                onClick={handleAuthClick}
              />
            )}
          </div>

          <MenuIcon
            className="menu-icon"
            width={24}
            height={24}
            color={COLOR.grayScale_700}
            onClick={() => setIsVisibleDrawer(true)}
          />
        </Styled.authAndMenuContainer>
      </Styled.mobileHeader>

      {isVisibleDrawer && (
        <Styled.mobileDrawerContainer onClick={() => setIsVisibleDrawer(false)}>
          {!IS_UNDER_PRODUCTION && APP_BUILD_INFO && (
            <div className="build-info">
              {formatDate({
                date: APP_BUILD_INFO.builtAt,
                type: "YY_MM_DD_HH_mm_ss",
              })}
            </div>
          )}

          <Styled.mobileAuthAndMenuContainer
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {!loggedIn && (
              <div className="auth-container">
                <Button
                  label={t("containers:Layout.회원가입")}
                  theme="tertiary"
                  borderType="filled"
                  size="normal"
                  width="50%"
                  handleClick={() => {
                    Router.push("/register");
                  }}
                />

                <Button
                  label={t("containers:Layout.로그인")}
                  size="normal"
                  theme="secondary"
                  borderType="filled"
                  width="50%"
                  handleClick={() => setIsVisibleLoginModal(true)}
                />
              </div>
            )}

            <nav className="menu-container">
              <ul>
                <MenuItem
                  label={t("containers:Layout.홈")}
                  path="/"
                  handleDrawerClose={handleDrawerClose}
                />

                <MenuItem
                  label={t("containers:Layout.회사소개")}
                  path="/company"
                  handleDrawerClose={handleDrawerClose}
                />

                <MenuItem
                  label={t("containers:Layout.서비스")}
                  onSelect={handleMenuSelect}
                  selected={activeMenuLabel === t("containers:Layout.서비스")}
                >
                  <ul onClick={handleDrawerClose}>
                    <Styled.submenuTitle>Forwarding</Styled.submenuTitle>

                    <SubmenuItem
                      label={t("containers:Layout.해상운송")}
                      desc={t("containers:Layout.수출입_해상운임_견적의뢰")}
                      path="/forwarding/ocean"
                      badges={["FCL", "LCL"]}
                      Icon={<SubmenuVesselIcon width={28} height={28} />}
                    />

                    <SubmenuItem
                      label={t("containers:Layout.항공운송")}
                      desc={t("containers:Layout.수출입_항공운임_견적의뢰")}
                      path="/forwarding/air"
                      badges={["AIR"]}
                      Icon={<SubmenuFlightIcon width={28} height={28} />}
                    />

                    <SubmenuItem
                      label={t("containers:Layout.스케줄_조회")}
                      desc={t("containers:Layout.해운/항공사별_스케줄_조회")}
                      path="/forwarding/schedule"
                      badges={["ALL"]}
                      Icon={<SubmenuCalendarIcon width={28} height={28} />}
                    />
                  </ul>

                  <MobileDivide />

                  <ul onClick={handleDrawerClose}>
                    <Styled.submenuTitle>Fulfillment</Styled.submenuTitle>

                    <SubmenuItem
                      label={t("containers:Layout.국내_출고")}
                      desc={t(
                        "containers:Layout.스마트스토어__쿠팡__카페24_등_국내출고"
                      )}
                      path="/fulfillment/domestic"
                      badges={["B2B", "B2C"]}
                      Icon={<SubmenuTruckIcon width={28} height={28} />}
                    />

                    <SubmenuItem
                      label={t("containers:Layout.해외_출고")}
                      desc={t(
                        "containers:Layout.Shopify__Cafe24_Global_등_해외출고"
                      )}
                      path="/fulfillment/overseas"
                      badges={["B2B", "B2C"]}
                      Icon={<SubmenuInternationalIcon width={28} height={28} />}
                    />

                    <SubmenuItem
                      label={t("containers:Layout.이벤트_출고")}
                      desc={t(
                        "containers:Layout.와디즈__텀블벅__홈쇼핑_등_단발성_대량출고"
                      )}
                      path="/fulfillment/event"
                      badges={["B2B", "B2C"]}
                      Icon={<SubmenuSurpriseIcon width={28} height={28} />}
                    />
                  </ul>

                  <MobileDivide />

                  <ul onClick={handleDrawerClose}>
                    <Styled.submenuTitle>Trade Management</Styled.submenuTitle>

                    <SubmenuItem
                      label={t("containers:Layout.대금결제_T/T_")}
                      desc={t(
                        "containers:Layout.은행_방문없이_온라인_무역대금_이체"
                      )}
                      path="/tt"
                      Icon={<SubmenuGiveMoneyIcon width={28} height={28} />}
                    />

                    <SubmenuItem
                      label={t(
                        "containers:Layout.상품소싱_쉽다_X_알리바바닷컴_"
                      )}
                      desc={t(
                        "containers:Layout.상품소싱하고_물류비_할인쿠폰_수령"
                      )}
                      path={alibabaUrl}
                      Icon={<SubMenuSourcingIcon width={28} height={28} />}
                      gtmEvent={alibabaGtmEvent}
                    />
                  </ul>
                </MenuItem>

                <MenuItem
                  label={t("containers:Layout.기술")}
                  onSelect={handleMenuSelect}
                  selected={activeMenuLabel === t("containers:Layout.기술")}
                >
                  <ul onClick={handleDrawerClose}>
                    <SubmenuItem
                      label="오픈 API"
                      desc="발주, 운송 등 Open API 문서 제공"
                      path="https://developers.ship-da.com/" // TODO: 새 탭으로 열면 어떨지 문의 중.
                      Icon={<SubmenuCodeIcon width={28} height={28} />}
                    />
                  </ul>
                </MenuItem>

                <MenuItem
                  label={t("containers:Layout.고객지원")}
                  onSelect={handleMenuSelect}
                  selected={activeMenuLabel === t("containers:Layout.고객지원")}
                >
                  <ul onClick={handleDrawerClose}>
                    <SubmenuItem
                      label={t("containers:Layout.가이드")}
                      desc={t(
                        "containers:Layout.이용방법__용어_및_프로세스__FAQ"
                      )}
                      path="/support/guide"
                      Icon={<SubmenuBookIcon width={28} height={28} />}
                    />

                    <SubmenuItem
                      label={t("containers:Layout.공지사항")}
                      desc={t("containers:Layout.서비스/정책_주요사항_안내")}
                      path="/support/notice"
                      Icon={<SubmenuAnnouncementIcon width={28} height={28} />}
                    />

                    <SubmenuItem
                      label={t("containers:Layout.동영상")}
                      desc={t("containers:Layout.서비스_및_상세_프로세스_영상")}
                      path="/support/onlinelecture"
                      Icon={<SubmenuPlayerIcon width={28} height={28} />}
                    />
                  </ul>
                </MenuItem>

                <MenuItem
                  label={t("containers:Layout.블로그")}
                  path="https://www.ship-da.com/blog"
                  handleDrawerClose={handleDrawerClose}
                />
              </ul>
            </nav>

            {loggedIn && (
              <Logout buttonInfo={{ size: "normal", width: "100%" }} />
            )}

            <XMarkDefaultIcon
              width={32}
              height={32}
              color={COLOR.grayScale_700}
              onClick={() => setIsVisibleDrawer(false)}
              className="x-mark-default-icon"
            />
          </Styled.mobileAuthAndMenuContainer>
        </Styled.mobileDrawerContainer>
      )}
    </>
  );
}
